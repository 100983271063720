import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { motion } from "framer-motion";
import { InView } from 'react-intersection-observer';


import TypewriterEffect from "../components/TypewriterEffect.jsx"
import Banner from '../components/Banner.jsx';
import ThreeJSModel from '../components/ThreeJSModel.jsx';
import ThemedButton from '../components/ThemedButton.jsx';
import Contact from '../components/Contact.jsx';


const SplashScreen = () => {
    const navigate = useNavigate();
    const [introComplete, setIntroComplete] = useState(true); // Set introComplete to true initially
    const [showModal, setShowModal] = useState(false);

    const handleProductDemoClick = () => {
        navigate('/video-demo');
    };

    if (!introComplete) {
        return null; // No loading animation, just return null until intro is complete
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75 }}
            className="flex flex-col bg-black text-white relative"
        >
            <Banner />
            <div className="px-6 space-y-8 xl:space-y-16 xl:px-20 ">
                {/* {Page 1} */}
                <div className="flex flex-col justify-start lg:flex-row lg:justify-between ">

                    {/* Title Text */}
                    <div className="lg:w-7/12 flex flex-col justify-between pt-36 lg:pt-72 ">
                        <TypeAnimation
                            sequence={[
                                '01_Mission:',
                                1000 // Waits 1 second
                            ]}
                            speed={30}
                            cursor="_"
                            repeat={0}
                            wrapper="span"
                            className="text-xl md:text-3xl lg:text-3xl 2xl:text-4xl font-bold py-4" />

                        <h1 className="text-5xl md:text-6xl lg:text-7xl 2xl:text-8xl font-bold">Build Faster. <br /> Win Harder.</h1>

                        <p className="text-sm lg:text-lg 2xl:text-xl text-neutral-400 w-3/4 my-8 2xl:my-10 whitespace-pre-line">
                            Forerunner is revolutionizing aerospace engineering. With our suite of automations and agentic workflows, we give startups the <span className='font-extrabold text-white '>engineering might</span> of industry leaders
                        </p>
                        {/* <div className="flex space-x-4">
                        <ThemedButton buttonText="Watch Demo" onClick={handleProductDemoClick} />
                         </div> */}
                        <div className="flex flex-col items-start justify-end w-full pt-8 sm:pt-12 lg:pt-20 2xl:pt-36 2xl:pb-16">

                            <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold mb-2">Trusted by: </h2>
                            <div className="flex flex-wrap justify-start items-end space-x-2 space-y-4 sm:space-x-4 md:space-x-10 ">
                                <img src="/CompanyLogos/Astranis.png" alt="Company 1 Logo" className="h-8 sm:h-10 md:h-14" />
                                <img src="/CompanyLogos/careweather.png" alt="Company 3 Logo" className="h-4 sm:h-6 md:h-8" />
                                <img src="/CompanyLogos/Theseus.png" alt="Company 2 Logo" className="h-8 sm:h-10 md:h-14" />
                            </div>
                        </div>
                    </div>

                    {/* CAD Window */}
                    <div className="lg:w-5/12 flex flex-col justify-center items-center my-10 ">

                        <div className="w-full my-12 lg:h-full outline rounded-lg outline-neutral-200">

                            <div className='h-auto text-sm flex flex-col items-center bg-neutral-200 text-black w-full px-4 py-1 rounded-t-lg'>
                                <span className="flex items-center">
                                    <img src="/Forerunner_Logo.ico" alt="Forerunner Logo" className="inline-block rounded-xl h-5 mr-2" style={{ backgroundColor: 'transparent' }} />
                                    forerunner_instance - zsh
                                </span>
                            </div>

                            {/*cad window*/}
                            <div className="w-full h-64 xl:h-1/2 2xl:h-3/5 outline outline-neutral-200">
                                <ThreeJSModel distance={800} filePath="/testEngine.glb" />
                            </div>

                            <div className="w-full pointer-events-none h-64 lg:h-2/5 p-2 flex flex-col justify-start overflow-hidden">
                                <TypewriterEffect
                                    className="w-full"
                                    texts={[
                                        "willnida@Wills-MBP ~ %: What mass flow rate will our engine's oxidizer turbopump have during steady-state operation?",
                                        "Forerunner: <span class=\"underline underline-offset-2	text-neutral-100\">Based on this Confluence Page</span>, you should design for 800 kg/sec oxidizer mass flow rate during steady-state.",
                                        "willnida@Wills-MBP ~ %: Help me write a Python script that calculates the turbopump parameters needed to attain the desired mass flow rate.",
                                        "Forerunner: <span class=\"underline underline-offset-2	text-neutral-100\">Here is a Python Script</span>, that will calculate the pressure rise, efficiency, and vane geometry needed to attain your desired mass flow rate."
                                    ]}
                                    speed={30}
                                    lineDelay={2000}
                                    preTextDelay={4000}
                                />
                            </div>
                        </div>
                    </div>
                </div >

                {/* New Section */}
                <div className="h-0 outline outline-1 " />


                {/* Product */}
                <div className="lg:w-full flex flex-col justify-center space-y-4">
                    <InView triggerOnce >
                        {({ inView, ref }) => (
                            <div className="pt-12" ref={ref}>
                                {inView && (
                                    <TypeAnimation
                                        sequence={[
                                            '02_Software:',
                                            1000 // Waits 1 second
                                        ]}
                                        speed={30}
                                        cursor="_"
                                        repeat={0}
                                        wrapper="span"
                                        className="text-xl md:text-3xl lg:text-3xl 2xl:text-4xl  font-bold"
                                    />
                                )}
                            </div>
                        )}
                    </InView>

                    <h1 className="text-5xl md:text-6xl lg:text-7xl 2xl:text-8xl font-bold">When time is your enemy, <br /> Automation is your ally.</h1>
                    <p className="text-sm lg:text-lg 2xl:text-xl text-neutral-400 py-4 lg:py-8 2xl:py-10 whitespace-pre-line">
                        Forerunner provides a suite of automations and agentic workflows, tailored to each company's needs
                    </p>
                    <div className="flex flex-col lg:mx-8">
                        {[
                            {
                                name: "⌖ Engineering Search",
                                description: "Forerunner can answer any question via natural language"
                            },
                            {
                                name: "⌖ Analysis",
                                description: "Setting up and executing handcalcs takes hours, Forerunner does it in seconds "
                            },
                            {
                                name: "⌖ Organization",
                                description: "Forerunner organizes and versions your analysis and data files, making collaboration easier."
                            },
                            {
                                name: "⌖ Imagine",
                                description: "The Forerunner team will write bespoke automations for your company's needs"
                            }
                        ].map((feature, index) => (
                            <div key={index} className={`flex flex-col md:flex-row justify-between items-start md:items-center border-b py-6 md:py-10 border-gray-300 ${index === 0 ? 'border-t' : ''}`}>
                                <h3 className="text-lg md:text-xl lg:text-2xl font-bold">{feature.name}</h3>
                                <p className="text-xs md:text-sm lg:text-base text-neutral-400 mt-2 md:mt-0 md:text-right md:max-w-md">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center pt-16">
                        <ThemedButton
                            buttonText="Watch Demo"
                            onClick={() => setShowModal(true)}
                        />
                    </div>

                    {showModal && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="fixed outline bottom-0 left-0 w-full h-screen bg-neutral-950 bg-opacity-85 flex justify-center items-center z-50"
                            onClick={() => setShowModal(false)} // Close modal on click outside
                        >
                            <div
                                className="bg-white p-1 rounded-xl shadow-lg max-w-5xl w-full relative"
                                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
                            >
                                <button
                                    className="fixed top-4 right-4 text-white hover:text-gray-700"
                                    onClick={() => setShowModal(false)}
                                >
                                    &times;
                                </button>
                                <iframe
                                    className="w-full aspect-video rounded-xl"
                                    src="https://www.youtube.com/embed/0EsKdLXPFAs"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </motion.div>
                    )}
                </div>
                {/* New Section */}
                <div className="h-0 outline outline-1" />

                {/* Contact Section */}
                <div className="lg:w-full flex flex-col justify-center space-y-4">
                    <InView triggerOnce >
                        {({ inView, ref }) => (
                            <div className="pt-12" ref={ref}>
                                {inView && (
                                    <TypeAnimation
                                        sequence={[
                                            '03_Contact:',
                                            1000 // Waits 1 second
                                        ]}
                                        speed={30}
                                        cursor="_"
                                        repeat={0}
                                        wrapper="span"
                                        className="text-xl md:text-3xl lg:text-3xl 2xl:text-4xl  font-bold"
                                    />
                                )}
                            </div>
                        )}
                    </InView>

                    <p className="text-sm lg:text-lg 2xl:text-xl text-neutral-400 w-3/4  whitespace-pre-line ">

                    </p>

                    <div className="flex flex-col items-center md:items-start md:flex-row space-y-8">
                        <div className="flex flex-col space-y-4">
                            {/* <h1 className="text-3xl font-extrabold mb-4 text-white text-left">Book a Demo</h1> */}
                            <Contact />
                        </div>
                        <div className="flex flex-col mx-auto space-y-4 ">
                            <h1 className="text-3xl font-extrabold mb-4 text-white text-left">Connect With Us</h1>
                            {/* <h2 className="text-xl font-bold mb-2">Contacts</h2> */}
                            <div className="px-6 pb-6 text-white border-b border-gray-300">
                                <h3 className="text-xl font-bold mb-2">Yash Kadadi</h3>
                                <p className="text-sm">Email: yash@tryforerunner.com</p>
                                <div className="flex space-x-4 mt-4">
                                    <a href="https://x.com/yamu1001" target="_blank" rel="noopener noreferrer">
                                        <img src="/SocialIcon/x.png" alt="Twitter" className="h-6" />
                                    </a>
                                    <a href="https://www.linkedin.com/in/yashkadadi/" target="_blank" rel="noopener noreferrer">
                                        <img src="/SocialIcon/linkedin.png" alt="LinkedIn" className="h-6" />
                                    </a>
                                    <a href="https://www.ycombinator.com/launches/M96-forerunner-copilot-for-aerospace-engineers" target="_blank" rel="noopener noreferrer">
                                        <img src="/SocialIcon/yc.png" alt="Y Combinator" className="h-6" />
                                    </a>
                                </div>

                            </div>
                            <div className="p-6 text-white border-b border-gray-300">
                                <h3 className="text-xl font-bold mb-2">Will Nida</h3>
                                <p className="text-sm">Email: will@tryforerunner.com</p>
                                <p className="text-sm">Phone: (952) 270-7833</p>
                                <div className="flex space-x-4 mt-4">
                                    <a href="https://x.com/willnida0" target="_blank" rel="noopener noreferrer">
                                        <img src="/SocialIcon/x.png" alt="Twitter" className="h-6" />
                                    </a>
                                    <a href="https://www.linkedin.com/in/william-nida/" target="_blank" rel="noopener noreferrer">
                                        <img src="/SocialIcon/linkedin.png" alt="LinkedIn" className="h-6" />
                                    </a>
                                    <a href="https://www.ycombinator.com/launches/M96-forerunner-copilot-for-aerospace-engineers" target="_blank" rel="noopener noreferrer">
                                        <img src="/SocialIcon/yc.png" alt="Y Combinator" className="h-6" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                <div className="h-0 outline outline-1" />
                <div className="lg:w-full flex flex-col justify-center space-y-4">
                    <InView triggerOnce >
                        {({ inView, ref }) => (
                            <div className="pt-12" ref={ref}>
                                {inView && (
                                    <TypeAnimation
                                        sequence={[
                                            '04_About:',
                                            1000 // Waits 1 second
                                        ]}
                                        speed={30}
                                        cursor="_"
                                        repeat={0}
                                        wrapper="span"
                                        className="text-xl md:text-3xl lg:text-3xl 2xl:text-4xl  font-bold"
                                    />
                                )}
                            </div>
                        )}
                    </InView>

                    <p className="text-sm lg:text-lg 2xl:text-xl text-neutral-400  whitespace-pre-line ">
                        We are a team of hardware and software engineers, formerly with:
                    </p>

                    <div className="pb-4">
                        <div className="flex flex-wrap items-end justify-center space-x-8 space-y-8 py-4">
                            {['spacex', 'anduril', 'nasa', 'yc', 'stanford'].map((company, index) => (
                                <img key={index} src={`OurIcon/${company}.png`} alt={`${company} Logo`} className="h-6 md:h-8 lg:h-10" />
                            ))}
                        </div>
                    </div>


                </div>

            </div>
            <div className="border-t border-gray-300 mt-4"></div>


            <footer className="bg-black text-white w-full">
                <div className="flex justify-between items-center py-12 px-2 lg:px-12 w-full">
                    <div className="flex flex-col items-start">
                        <div className="flex items-center justify-start">
                            <img src="/Forerunner_Logo.png" alt="Company Logo" className="h-6 lg:h-10 mr-2" />
                            <span className="font-bold text-white text-lg lg:text-3xl">Forerunner</span>
                        </div>
                        <span className="text-md text-white ml-8 lg:ml-12">Build Faster. <br /> Win Harder.</span>

                        <div className="flex space-x-4 mt-4 ml-8 lg:ml-12">
                            <a href="https://x.com/TryForerunner" target="_blank" rel="noopener noreferrer">
                                <img src="/SocialIcon/x.png" alt="Twitter" className="h-4 lg:h-6" />
                            </a>
                            <a href="https://www.linkedin.com/company/tryforerunner/" target="_blank" rel="noopener noreferrer">
                                <img src="/SocialIcon/linkedin.png" alt="LinkedIn" className="h-4 lg:h-6" />
                            </a>
                            <a href="https://www.ycombinator.com/launches/M96-forerunner-copilot-for-aerospace-engineers" target="_blank" rel="noopener noreferrer">
                                <img src="/SocialIcon/yc.png" alt="Y Combinator" className="h-4 lg:h-6" />
                            </a>
                        </div>
                    </div>
                    <div className="text-sm flex flex-col items-start">
                        <a href="/privacy-policy" className="hover:underline">01_Privacy Policy</a>
                        <a href="/terms-of-service" className="hover:underline mt-1">02_Terms of Service</a>
                    </div>

                </div>
                <div className="border-t border-neutral-600"></div>

                <span className="text-sm mx-4  text-neutral-500">&copy; {new Date().getFullYear()} Forerunner AI Inc. All rights reserved.</span>

            </footer>
        </motion.div >
    );
};

export default SplashScreen;
